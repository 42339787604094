<template>
  <div>
    <auth-navbar />
    <div class="c-app flex-row ">
      <CContainer>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard class="p-4 border-0">
                <CCardBody>
                  <CForm>
                    <h1 class="text-main_black font-2xl font-weight-normal" v-if="!logStatus">Authorize</h1>
                    <div class="d-flex flex-row align-items-start" v-else>
                      <img src="@/assets/icons/ic_success.svg" alt="">
                      <h4 class="font-weight-normal ml-2">Your password has been successfully reset</h4>
                    </div>
                    <div class="mt-lg-4">
                      <p class="d-flex flex-column mb-3">
                        <label for="email">Login</label>
                        <input type="email" id="email" placeholder="Email" v-model="forms.email" :class="(error.message ? 'border-error_color' : '') + ' p-2 pl-3 bg-milk_mix border rounded-lg outline-none text-dark'"/>
                      </p>
                      <p class="d-flex flex-column mb-4">
                        <label for="password">Password</label>
                        <input type="password" id="password" v-model="forms.password" placeholder="Password" :class="(error.message ? 'border-error_color' : '') + ' p-2 pl-3 bg-milk_mix border rounded-lg outline-none text-dark'"/>
                        <span class="text-error_color mt-1">{{ error.message ? error.message : "" }}</span>
                      </p>
                    </div>
                    <CButton @click="onLogin"  :disabled="inputsAreValid()" color="submit" class="text-white p-2 mt-2 w-100 rounded-md" shape="square">Sign in</CButton>
                    <CCard class="mt-2 ml-auto mr-auto text-center border-0 text-main_color">
                      <router-link to="/pages/recovery" class="text-main_color btn btn-link text-decoration-underline w-auto ">Forgot your password?</router-link>
                    </CCard>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AuthNavbar from '../base/AuthNavbar';
import {Trans } from "../../plugins/Translation";

export default {
  name: 'Login',
  components: {AuthNavbar},
  data () {
    return {
      forms: {
        email: '',
        password: '',
      },
      logStatus: false,
      error:{
        message: null
      },
      loading: false,
      langs: Trans.supportedLocales
    }
  },
  created() {
    this.logStatus = !!this.$route.query.status;
  },
  methods: {
    inputsAreValid: function() {
      return this.emailIsValid() || this.passwordIsValid();
    },
    changeLanguage(locale){
      Trans.changeLocale(locale);
    },
    emailIsValid: function() {
      return this.forms.email.length === 0;
    },
    passwordIsValid: function() {
      return this.forms.password.length === 0;
    },
    onLogin: function(){
      this.loading = true;
      axios({
        method: 'post',
        url: process.env.VUE_APP_API+'/api/v1/login',
        data: {
          email: this.forms.email,
          password: this.forms.password
        },
      })
      .then((response) => {
        if(response.data && response.data.token && response.data.token.length>1){
          //console.log(response.data.token)
          localStorage.setItem('btsminer_token', response.data.token);
          this.$router.push('/Farms/1');
        }else
        this.loading = false;
      })
      .catch((error) => {
        if(error.response.status === 403) this.$router.push('status/error')
        if(error.response.data.errors){
          this.error.message = error.response.data.errors[0];
          return;
        }
        this.loading = false;
        this.error.message = error.response.data.message;
      })
      .then(()=>{
        this.loading = false;
      });
    },
  }
}
</script>